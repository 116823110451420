import React from "react";
import {
  Form,
  Checkbox,
  Input,
  Col,
  Row,
  Typography,
  Layout,
  Card,
  Button,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { REQUEST } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import swal from "sweetalert";
import { ImageUrl } from "../../helpers/Function";

//icons
import { IoMdPin } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FiMail, FiLock } from "react-icons/fi";

function Requests() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    Post(REQUEST.addRequest, values)
      .then((response) => {
        console.log("response", response);
        setLoading(false);
        if (response?.data?.status) {
          swal(
            "Success!",
            "Thankyou for your message. Our team will get back to you soon",
            "success"
          );
          form.resetFields();
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{
            fontSize: "30px",
            color: "#333d47",
            marginTop: 20,
            marginBottom: 0,
          }}
        >
          {<> Request</>}
        </Typography.Title>
        <Typography.Text
          className="fontFamily1"
          style={{ fontSize: "18px", color: "#333d47", margin: 10 }}
        >
          {<> Submit your request to register as an Organization</>}
        </Typography.Text>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={10}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="contactCard1"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
              }}
            >
              <Form
                layout="vertical"
                name="basic"
                form={form}
                className="contactForm"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Full Name*"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your full name!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter FullName"
                    className="ContactFormInput"
                  />
                </Form.Item>

                <Form.Item
                  label="Email*"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please input valid email!",
                    },
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter Email"
                    className="ContactFormInput"
                  />
                </Form.Item>

                <Form.Item
                  label="Phone*"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter Phone Number"
                    className="ContactFormInput"
                  />
                </Form.Item>

                <Form.Item
                  label="Organization Name*"
                  name="organizationName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your organization Name",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter organization name"
                    className="ContactFormInput"
                  />
                </Form.Item>

                <Form.Item
                  label="Organization Description*"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input your organization description",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter organization description"
                    className="ContactFormInput"
                  />
                </Form.Item>

                <Form.Item
                  label="Message*"
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please input your message",
                    },
                  ]}
                >
                  <Input.TextArea
                    size="large"
                    placeholder="Enter Message"
                    className="ContactFormTextField"
                    rows={4}
                  />
                </Form.Item>

                <br />

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                  >
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Col>
        <Col xs={24} md={10}>
          <div
            style={{
              padding: "50px 20px",
            }}
          >
            <video width="auto" height="auto" controls>
              <source src={`https://react.customdev.solutions:3012/Uploads/enterprise-Registeration.mov`} />
              <source src="movie.ogg" type="video/ogg" />
            </video>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Requests;
