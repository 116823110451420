import React, { useEffect, useRef, useState } from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Card,
  Tabs,
  Table,
  Image,
  Divider,
  Rate,
  Modal,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, UPLOADS_URL } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { PiMedal } from "react-icons/pi";
import {
  FaArrowRight,
  FaCheck,
  FaCheckCircle,
  FaMedal,
  FaRegClock,
  FaRegStar,
} from "react-icons/fa";
import { TbBolt } from "react-icons/tb";
import { LiaBrainSolid } from "react-icons/lia";
import swal from "sweetalert";
import { ImageUrl } from "../../helpers/Function";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosMail } from "react-icons/io";
const { Meta } = Card;
// import router from "next/router";

function Homepage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);
  const videoRef = useRef(null);

  const [modal2Open, setModal2Open] = useState(false);

  useEffect(() => {
    if (modal2Open && videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.log("Autoplay failed:", error);
      });
    }
  }, [modal2Open]);
  // useEffect if user is already logged in
  React.useEffect(() => {
    if (!user && !token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const data1 = [
    {
      title:
        "Organizations can attach a logo to their portal and customize the URL of their site with a custom domain.",
    },
    {
      title:
        "LMS site admins can oversee the progress each organization is making in their respective portals.",
    },
  ];

  const data2 = [
    {
      title: "Enroll teams into training with group enrollment codes.",
    },
    {
      title: "Upload 1000s of users into a training portal in one step.",
    },
    {
      title:
        "Simultaneously enroll users into various courses while onboarding them into the system.",
    },
  ];

  const data3 = [
    {
      avatar: <FaCheckCircle />,
      title: "Manage Training Engagement",
      des: "Completion statuses, Certifications, Assignment scores, and more.",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Oversee Course Enrollment",
      des: "Number of active Users, Enrollment date, Last-active date.",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Report On Certifications And More...",
      des: "Track Certification statuses and review assignment submissions—then provide feedback.",
    },
  ];

  const data4 = [
    {
      avatar: <FaCheckCircle />,
      title: "Sell Course Licenses In Bulk",
      des: "and charge organizations with a one-time payment or a subscription model.",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Offer Discounts Using Coupon Codes",
      des: "Number of active Users, Enrollment date, Last-active date.",
    },
  ];

  const data5 = [
    {
      title: "Webinar tools such as Zoom, MS Teams, and more.",
    },
    {
      title: "CRMs like HubSpot and Salesforce.",
    },
    {
      title: "Analytics tools like GA4, FB Pixel, and JavaScript events.",
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh", backgroundSize: "cover" }}>
      <section>
        <Row className="cource-banner" justify={"center"} align={"middle"}>
          <Col xs={24} md={10}>
            <div className="">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={20} className="formWrap">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg3.png")}
                  />
                  <p className="text-p-14">LMS FOR B2B TRAINING</p>
                  <Typography.Title
                    className="fontFamily1 smallTitle"
                    style={{ color: "#fff" }}
                  >
                    {<> Train Organizations At Scale</>}
                  </Typography.Title>

                  <Typography.Text
                    className="fontFamily1 smallText"
                    style={{ color: "#fff" }}
                  >
                    Administer and scale your B2B training with a user-friendly
                    platform. Onboard—train different organizations
                    simultaneously—and manage courses and instructor-led
                    training with WrightCo Academy.
                  </Typography.Text>
                  <br />
                  <br />
                  <br />
                  {!token && <Button
                    type="primary"
                    htmlType="submit"
                    className="whiteButton"
                    onClick={() => navigate("/signup")}
                    style={{ marginLeft: "5px" }}
                  >
                    Register Now
                  </Button>}
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                    onClick={() => setModal2Open(true)}
                    style={{ display: "inline-flex", alignItems: "center", gap: "5px" }}
                  >
                    See The Platform <FaArrowRight />
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={0} sm={0} md={12}>
            <div className="" style={{ textAlign: "center" }}>
              <Row style={{ width: "100%", paddingRight: "20px" }} gutter={40}>
                <Col xs={0} sm={12} md={24}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-51.png")}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-52.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Educate Multiple Organizations Simultaneously</h4>
                  <p className="text-p-16">
                    Create separate training portals for each organization you
                    are training. Each portal is managed by one or multiple
                    employees from an organization, and portals are kept
                    separate from each other.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data1}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  {/* <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button> */}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>


      <section className="bg-gray  top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Organize Internal Training</h4>
                  <p className="text-p-16">
                    Organize your internal training by creating separate portals
                    for each department. Training portals can be managed by team
                    leaders.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data3}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  {/* <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/contact-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button> */}
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-38.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-blue top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg2.png")}
                  />
                  <h4>Swiftly Onboard & Enroll Users Into Training Courses</h4>
                  <p className="text-p-16">
                    Onboard organizations into training programs quickly, and
                    enroll employees in courses. WrightCo Academy is a practical
                    solution for both small and large organizations.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data2}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  {/* <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button> */}
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-53.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-gray top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg4.png")}
            />
            <h4 className="text-38">
              Track your Customers’ Progress with Learner Reports
            </h4>
            <p className="text-p-16">
              After onboarding a customer, administrators can keep track of
              their users’ progress and engagement using different reports.
            </p>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col xl={20} lg={18} md={23} xs={24}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[16, 16]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-34.png")}
                  />
                  <h5>Review Assignments and Give Feedback</h5>
                  <p>
                    Review assignment submissions, provide feedback, and give
                    grades directly inside of the LMS.
                  </p>
                  <span onClick={() => navigate("/")}>See Pricing</span>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-35.png")}
                  />
                  <h5>Track Completions - Scores - Time-Spent</h5>
                  <p>
                    When selling courses directly on our software, you can use
                    your logo and brand colors throughout the site.
                  </p>
                  <span onClick={() => navigate("/")}>See Pricing</span>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-36.png")}
                  />
                  <h5>Keep Track Of Learner Progress</h5>
                  <p>
                    Customers can report on learner progress in their portal by
                    looking at an individual or a group of learners.
                  </p>
                  <span onClick={() => navigate("/")}>See Pricing</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 text-white">
              Report On Learning Progress With WrightCo Academy
            </h4>
            <p className="text-p-16 text-white">
              Each organization you train manages its own program by assigning
              an admin to its portal. Admins can report on completion statuses,
              assessment scores, certifications, and more.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => {
                navigate(token ? "/profile" : "/signup");
              }}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-54.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={10}>
                <div className="list-box">
                  <List
                    itemLayout="horizontal"
                    dataSource={data3}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <span className="list-icon">{item.avatar}</span>
                          }
                          title={<h6 className="list-h6">{item.title}</h6>}
                          description={<p className="list-des">{item.des}</p>}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-gray top-bottom-padding">
        <Row
          justify={"center"}
          style={{ textAlign: "center", paddingBottom: "40px" }}
        >
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg4.png")}
            />
            <h4 className="text-38">
              Standardize Training For Global Companies
            </h4>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col xl={20} lg={18} md={23} xs={24}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[16, 16]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-34.png")}
                  />
                  <h5>Webinars & Seminars</h5>
                  <p>
                    Create multiple-choice, short answer, and other style
                    quizzes that can be static or dynamic.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-35.png")}
                  />
                  <h5>On-Demand Courses</h5>
                  <p>
                    Have learners read slides, answer questions, and participate
                    in different interactive assignments.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-36.png")}
                  />
                  <h5>Translate WrighCo Academy</h5>
                  <p>
                    Start a discussion for learners in a classroom to answer
                    questions individually and work together.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      {/* <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 text-white">
              Sell Training Directly On The LMS
            </h4>
            <p className="text-p-16 text-white">
              Fully customize the menus, buttons, and text sections in the LMS
              to better represent your company and training type.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => navigate("/about-us")}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-28.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={10}>
                <div className="list-box">
                  <List
                    itemLayout="horizontal"
                    dataSource={data4}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <span className="list-icon">{item.avatar}</span>
                          }
                          title={<h6 className="list-h6">{item.title}</h6>}
                          description={<p className="list-des">{item.des}</p>}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section> */}



      {/* <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg.png")}
                  />
                  <h4>All-In-One Platform For B2B Training</h4>
                  <p className="text-p-16">
                    WrightCo Academy connects with other software, so
                    organizations can use familiar business tools in their
                    training programs. See some examples of software you can use
                    with WrightCo Academy LMS:
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data5}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheck />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-55.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section> */}

      {/* <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 text-white">
              Customize the LMS with REST API & JavaScript
            </h4>
            <p className="text-p-16 text-white">
              The sky is the limit with our flexible REST API that allows you to
              build out anything you like with the data from WrighCo Academy. Use
              Custom JavaScript to customize WrighCo Academy as well.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => navigate("/about-us")}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={22}>
                <div className="" style={{ textAlign: "center" }}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-47.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section> */}

      {/* <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg.png")}
                  />
                  <h4>Integrate with your eCommerce Store</h4>
                  <p className="text-p-16">
                    Advertise and sell tickets to upcoming in-person classes,
                    online courses, and webinars on your website.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data4}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-16.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section> */}

      {/* <section className="bg-gray top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-48.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Connect with Webinar Tools</h4>
                  <p className="text-p-16">
                    Connect with third-party apps to create the ultimate
                    eLearning platform. With our integrations and developer-
                    friendly API, your learners can continue their education and
                    train online using familiar business tools.
                  </p>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    Learn About Webinar Tools
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section> */}

      {/* <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 text-white">
              Log in with Single Sign-on and 2-Step Verification
            </h4>
            <p className="text-p-16 text-white">
              Eliminate password fatigue by letting employees log in with their
              company credentials (Single-Sign on). Mitigate security risks with
              2-step verification.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => navigate("/about-us")}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={22}>
                <div className="" style={{ textAlign: "center" }}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-49.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section> */}

      {/* <section className="bg-gray top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Notify external systems automatically</h4>
                  <p className="text-p-16">
                    Using Webhooks, automatically send notifications to outside
                    systems when actions occur in the LMS.
                  </p>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-50.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section> */}

      {/* <section className="bg-white top-bottom-padding">
        <Row justify={"center"}>
          <Col xl={18} lg={17} md={23} xs={23}>
            <div className="on-demand">
              <h4>Efficiently Sell Courses & Webinars Online</h4>
              <Button
                type="primary"
                htmlType="submit"
                className="whiteButton"
                onClick={() => navigate("/about-us")}
                style={{ marginLeft: "5px" }}
              >
                Get Started
              </Button>
            </div>
          </Col>
        </Row>
      </section> */}


      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={16}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="contactCard1 terms-box"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
              }}
            >
              <h3> We’re Looking for Trainers</h3>
              <h3>Teach. Create. Earn.</h3>
              <p> Are you passionate about teaching? Ready to share your expertise, connect with professionals, and make a meaningful impact, all while earning extra income?</p>

              <h4> You've come to the right place.</h4>
              <p> At WrightCo Academy, we are building a network of world-class trainers and industry experts dedicated to developing high-quality courseware for professionals in early care and education. If you’re an expert in your field with a desire to teach, we’d love to have you on board!</p>

              <h3> Why Join Us?</h3>
              <ul>
                <li>
                  <p>✅ Expand Your Reach – Share your knowledge with a broad audience of professionals.</p>
                </li>
                <li>
                  <p>✅ Boost Your Visibility – Gain recognition as a trusted expert in your industry.</p>
                </li>
                <li>
                  <p>✅ Join a Thriving Community – Connect with like-minded trainers and industry leaders.</p>
                </li>
                <li>
                  <p>✅ Earn Passive Income – Our fair and flexible revenue-sharing model ensures unlimited earning potential.</p>
                </li>
              </ul>

              <h3> What It Takes to Be a WrightCo Trainer</h3>
              <ul>
                <li>
                  <p>✔ <strong>Passion for Teaching</strong> – A commitment to sharing knowledge and empowering others.</p>
                </li>
                <li>
                  <p>✔ <strong>Industry Expertise</strong> – Relevant certifications and experience in your domain.</p>
                </li>
                <li>
                  <p>✔ <strong>Strong Communication Skills</strong> – Ability to present information effectively.</p>
                </li>
                <li>
                  <p>✔ <strong>Commitment to Excellence</strong> – Willingness to develop and deliver high-quality training.</p>
                </li>
              </ul>
              <h4> Ready to Make an Impact?</h4>
              <p> Join WrightCo Academy today and take the next step in your teaching journey.</p>
            </Card>
          </div>
        </Col>
      </Row>


      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Modal
          // title="Vertically centered modal dialog"
          centered
          open={modal2Open}
          onOk={() => setModal2Open(false)}
          onCancel={() => setModal2Open(false)}
          width={1000}
          footer={false}
        >
          <div
            style={{
              padding: "50px 20px",
            }}
          >
            <video width="auto" ref={videoRef} height="auto" controls>
              <source src={`https://react.customdev.solutions:3012/Uploads/studentVideo.mp4`} />
              <source src="movie.ogg" type="video/ogg" />
            </video>
          </div>
        </Modal>
      </Row>

    </Layout>
  );
}

export default Homepage;
