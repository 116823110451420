import { Col, Image, Layout, Row, Typography } from "antd";
import React, { useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import { ImageUrl } from "../../helpers/Function";

// const faqs = new Array(7).fill({
//     index: 1,
//     text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.",
//     innerText:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
// })

const faqs = [
    {
        index: 1,
        text: "What types of training programs do you offer? ",
        innerText: "We offer a range of online training programs for the early care and education industry, including foundational courses, specialized skill development, and professional development opportunities. Our training covers topics supporting every service area. Courses cover everything from board development to early childhood development, classroom management, early literacy, facilities, health and safety, and more.",
    },
    {
        index: 2,
        text: "How do I enroll in a course?",
        innerText: "Student registration is free and easy! On the home page, click the Join Us Free button and follow the instructions to register. You must create an account to track your progress and access course materials.",
    },
    {
        index: 3,
        text: "Can I take the courses at my own pace?",
        innerText: "Yes, most of our online training programs are self-paced. You can start and finish the courses according to your schedule or as your organization outlines, allowing you to balance your professional responsibilities with your learning.",
    },
    {
        index: 4,
        text: "Will I receive a certificate after completing a course?",
        innerText: "Yes, upon completing a course, you will receive a certificate of completion that you can use for professional development or to meet licensing requirements (where applicable).",
    },
    {
        index: 5,
        text: "What technology do I need to participate in online training?",
        innerText: "Our courses are compatible with most devices, including desktops, laptops, tablets, and smartphones. You only need an internet connection and a modern web browser (Chrome, Firefox, Safari, etc.) to access the training materials. If it's an in-person virtual training, no worries; we can work with any of the meeting platforms, including but not limited to Zoom, Webex, Microsoft Teams, or Google Meet.",
    },
    {
        index: 6,
        text: "Are your courses available in languages other than English?",
        innerText: "Currently, most of our courses are offered in English. Some translation features are built-in, and we are working on expanding our offerings. We hope to provide multilingual training options shortly. Please check the individual course descriptions for language availability.",
    },
    {
        index: 7,
        text: "Are the training programs accredited? ",
        innerText: "No, not at this time. However, we are working on accredited courses that will fulfill continuing education requirements. Please check the course details or contact us for more information on specific accreditation or certification.",
    },
    {
        index: 8,
        text: "Can I get a refund if I’m unsatisfied with a course I've taken? ",
        innerText: "We offer a satisfaction guarantee for our training programs. If you're unsatisfied with a course, please get in touch with us within a specified time frame (typically within 14 days of purchase) for a refund or course credit. Please take a look at our refund policy for more details.",
    },
    {
        index: 9,
        text: "How can I contact customer support if I have questions or issues?",
        innerText: "You can reach our customer support team by email at [support@wrightcoacademy.com] or by calling 877-701-2899. Our team can assist you with any enrollment, technical, or course-related questions.",
    },
    {
        index: 10,
        text: "Do you offer group or organizational training packages? ",
        innerText: "Yes! We offer customized group training solutions for organizations, and we have enterprise options. If you're interested in training for a team or organization, please get in touch with us to discuss your needs and get a quote. Call us at 877-701-2899.",
    },
    {
        index: 11,
        text: "How are your training programs delivered? ",
        innerText: "In addition to offering virtual and on-demand training, we offer in-person training  (at a location of your choice). We can also offer a customized hybrid model combining any two of the former. For in-person training, you can reach our customer training support team by email at training@wrightcoacademy.com or by calling us at 877-701-2899.",
    },
];

const FAQ = () => {
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Row
                className="blueBackground-old faqbnr"
                style={{
                    height: "45vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}
            >
                <Typography.Title
                    className="fontFamily1"
                    style={{ fontSize: "30px", color: "#333d47", marginTop: 20, marginBottom: 0 }}
                >
                    {<> FAQ</>}
                </Typography.Title>
                <Typography.Text
                    className="fontFamily1"
                    style={{ fontSize: "18px", color: "#333d47", margin: 10 }}
                >
                    {<> Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro quibusdam tempora odit veritatis commodi</>}
                </Typography.Text>
            </Row>



            {/* section 2 */}
            <Row
                className="whiteBackground"
                style={{ backgroundColor: "white", justifyContent: "center" }}
            >
                <Col xs={24} md={20}>
                    <div className="flex justify-between gap-20 py-20">
                        <div className="w-1/2">

                            <Rows />
                        </div>
                        <div className="w-1/2 flex">
                            <div className="h-[450px] w-[350px]">

                                <Image
                                    alt={"Failed to load image"}
                                    preview={false}
                                    src={ImageUrl("faq.jpg")}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Layout>
    )
}

export default FAQ


const Rows = () => {
    const CustomRow = ({ faq }) => {
        const [expanded, setExpanded] = useState(false);

        const toggleExpand = () => {
            setExpanded(!expanded);
        };

        return (
            <Row justify="center">
                <Col xs={24} className="bg-white">
                    <div className="flex flex-col justify-center items-center bg-[#00A79D] bg-opacity-70 mb-4 rounded-[10px] overflow-hidden">
                        <div
                            className="flex justify-between items-center px-6 py-4 w-full cursor-pointer bg-[#228a83]"
                            onClick={toggleExpand}
                        >
                            <p className="font-popin text-md max-sm:text-xs text-white font-medium">
                                {faq.text}
                            </p>
                            <TiArrowSortedDown
                                className={`text-white min-w-[28px] min-h-[28px] pl-2 ${expanded ? "transform rotate-180" : ""
                                    }`}
                            />
                        </div>
                        <div
                            className={`border-t border-white ${expanded
                                ? "max-h-[1000px] opacity-100 transition-all duration-500 px-6 py-4"
                                : "max-h-0 opacity-0 transition-all duration-500"
                                }`}
                        >
                            <p className="font-euclid text-sm max-sm:text-xs text-white font-normal leading-relaxed">
                                {faq.innerText}
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    };

    return (
        <Row justify="center" className="py-12">
            <Col xs={24}>
                {faqs?.map((faq, index) => (
                    <CustomRow key={index} faq={faq} />
                ))}
            </Col>
        </Row>
    );
};