import {
  Button,
  Col,
  Layout,
  Row,
  Typography
} from "antd";
  
import { TfiComment } from "react-icons/tfi";
import codeImage from "../../images/Code.png";  
import code2Image from "../../images/Code2.png";  

import { FaRegStar } from "react-icons/fa6";
import { BsFillStarFill } from "react-icons/bs";
import { FaStarHalfAlt } from "react-icons/fa";
import Comment from "../../components/comment";
import { useParams } from 'react-router-dom'
import { useEffect, useState } from "react";
import { Tabs } from 'antd';
import { PiDotsThreeOutlineFill } from "react-icons/pi";

import { useSelector } from "react-redux";
import { Get } from "../../config/api/get";
import { COMMUNITY, UPLOADS_URL, UPLOAD_URL2 } from "../../config/constants/api";
import swal from "sweetalert";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Post } from "../../config/api";


  export default function CommunityPost() {

    const { _id } = useParams();
    const navigate = useNavigate()
  
    const [post,setPost]= useState({})
    const [loading, setLoading] = useState(true);
    const token = useSelector((state) => state.user.userToken);

    const [comment,setComment]= useState("")
    const [commentLoader,setCommentLoader]= useState(false)
    const [isHide ,setIsHide] = useState(true)
    

    async function getPosts() {
      try {
        const response = await Get(COMMUNITY.getPost+"/"+_id, token,{});
        console.log("responst => ",response)
        if (response.status === true) {
          setLoading(false);
          setPost(response?.data);
        } else {
          swal("Error", "Something went wrong", "error");
        }
      } catch (error) {
        swal("Error", error?.message || "something went wrong" , "error");
        console.log(error);
      
      }
     }

 
    useEffect(() => {
      getPosts()
    }, [_id])


    const comments = {
          name: 'John Doe',
          time: '2 hours ago',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare rutrum amet, a nunc mi lacinia in iaculis. Pharetra ut integer nibh urna. Placerat ut adipiscing nulla lectus vulputate massa, scelerisque. Netus nisl nulla placerat dignissim ipsum arcu.',
          image: code2Image
    }

    const user = {
      name :"John Doe",
      image: "https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png"
    }
 

    const [selected,setSelected] = useState("post") // mebmers profile
    const onChange = (key) => {
      setSelected(key);
    };


    const tabs= [
      {
        label: "Post",
        key: "post",
        children: "Post",
      },
      {
        label: "Members",
        key: "members",
        children: "Members",
      },
      {
        label: "Profile",
        key: "profile",
        children: "Profile",
      },
    ]

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);  
    
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);  // Update state when resizing
      };
        // Attach event listener to the window resize event
      window.addEventListener('resize', handleResize);
        // Cleanup the event listener when the component is unmounted
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); // Empty dependency array ensures it only runs once on mount
  
    
      
    function sendComment(){
      setCommentLoader(true)
      if(!comment) {
        swal(
          "Oops!",
          "Please Enter Your Comment Before Submitting.",
          "error"
        );
        setCommentLoader(false)

        return
      }
      Post(`/community/${post?._id}/comment`, {text:comment}, token, null)
      .then((response) => {
        setCommentLoader(false)
        if (response?.data?.status) {
          swal("Success", "Comment added successfully", "success");
          setComment("")
          getPosts()
        } else {
          swal(
            "Oops!",
            response?.data?.message || response?.response?.data?.message,
            "error"
          );
          setCommentLoader(false)
        }
      })
      .catch((e) => {
        setCommentLoader(false)
        swal("Error","An error occurred while adding the comment to the post.")
      });
    }


    return (
      <Layout  style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "30vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{
            fontSize: "30px",
            color: "#333d47",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {<> Community</>}
        </Typography.Title>
      </Row>
          
          <Row className="flex justify-center items-center gap-5 mt-3 md:hidden ">

          <Tabs
              onChange={onChange}
              type="card"
              className={`text-4xl w-full flex justify-center items-center gap-5 py-3 px-6 `}
              items={tabs.map((tab, i) => {
                return {
                  label: tab.label,
                  key: tab.key,
                  // children: `Content of Tab Pane ${tab.key}`,
                };
              })}
            />

          </Row>
  
          <Row className="sm:mx-2 lg:mx-6 my-8">

            {/* List of User */}
            <Col  xs={24} md={5} className={`${selected !== "members" && isMobile ? "hidden" : "userCol"} `}   >
              <div className="h-[700px] md:h-[1000px] w-full overflow-hidden bg-slate-200 mx-6 sm:mx-0 rounded-xl">
            <p className="text-center my-6 text-xl sm:text-lg lg:text-xl font-serif font-bold">Community Members</p>

            <div className="h-[1000px] overflow-y-auto">

          <div className="mt-8 px-2 sm:px-2 lg:px-6 flex flex-col gap-10 items-start">

            {post?.members?.length > 0 ? 
              post?.members.map((member, index) => (
            <div key={index} className="flex justify-start items-center gap-5">
            <img 
               src={member.image? UPLOAD_URL2 + member.image : "https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2247726673.jpg"}
               className="w-20 h-20 sm:w-14 sm:h-14 lg:w-20 lg:h-20 rounded-full"
            />
            <p className="text-xl sm:text-sm lg:text-lg font-semibold">{member.firstName + " "+ member.lastName}</p>

            </div>
              ))
              :
              <p className="text-center w-full text-xl sm:text-sm lg:text-lg font-semibold">No Members Found</p>

            }
     
              

          </div>



          </div>

          
            </div>
           
            </Col>

            {/* Post Column */}
            <Col xs={24} md={14} className={`${selected !== "post" && isMobile ? "hidden" : "otherCol" } `}  >
              <div className="w-full h-fit  md:px-5 lg:px-10 py-2 ">

              <div className="shadow-slate bg-white p-4">
             
              {/* User profile */}
              <div className="flex p-2 justify-start items-center gap-4">
                <img 
                src={post?.user?.image ? UPLOADS_URL+"/" + post.user.image : "https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2247726673.jpg"}
                className="w-20 h-20 lg:w-16 lg:h-16 rounded-full"
                />
                <div className="flex flex-col items-start gap-[-2px]">
                  {post?.user?.firstName && 
                  <>
                  <p className="text-xl lg:text-xl font-bold">{post?.user?.firstName +" "+ post?.user?.lastName}</p>
                  <p className=" text-sm lg:text-sm ">{moment(post?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
                  </>
                  }
                </div>
              </div>







              {/* Post details */}
              {Object.keys(post).length !== 0 ? 
                 <div className="mt-8 md:px-1 ">
                <p className="text-2xl sm:text-2xl lg:text-xl font-bold ">{post.title}</p>
                <div className="mt-1 lg:mt-6 text-sm"  dangerouslySetInnerHTML={{ __html: post.description }} />

                {/* Post Image */}
                {post?.thumbnail && (
                     <img
                     src={UPLOAD_URL2+post.thumbnail}
                     alt="Post Image"
                     className="w-full h-[300px] sm:h-[300px] lg:h-[700px] object-fill  mt-4"
                     />
                )
                }
             

                </div>

              :
              <div className="w-full flex jus items-center ">
              <div class="w-16 h-16 border-4  border-dashed border-t-4 border-black rounded-full animate-spin"></div>
              </div>
              }
                
              </div>
              {/* Post details */}


              {/*Add Comments */}
              <div className="mt-12 shadow-slate  p-4">
                <p className="text-lg sm:text-xl lg:text-xl  text-center my-3 font-semibold">Comments</p>


                <div className="mt-4">
                      <input
                      onChange={e => setComment(e.target.value)}
                      value={comment}
                      type="text"
                      placeholder="Type here your wise comments"
                      className="w-full h-10 sm:h-10 lg:h-12 rounded-lg p-4  focus:outline-none border-[1px] border-slate-300"
                      />
                    <div>
             <div className="flex gap-3 items-center my-2 justify-end">
            <div
            onClick={() => setComment("")}
            className="bg-slate-200 cursor-pointer hover:bg-slate-300 w-32 h-10 sm:w-32 sm:h-8  lg:w-36 lg:h-12 rounded-xl text-sm sm:text-sm lg:text-xl  flex justify-center items-center gap-4  mt-5 ">
              delete
            </div>

            <div
            onClick={() =>{
              if(commentLoader) return
              sendComment()
            }}
            className="bg-[#00A79D] hover:bg-[#1d827b] text-white w-32 h-10 sm:w-32 sm:h-8  lg:w-36 lg:h-12 rounded-xl text-sm sm:text-sm lg:text-xl flex justify-center items-center gap-4  mt-5 ">
              {commentLoader? 
                  <div className="w-full flex jus items-center ">
                  <div class="w-6 h-6 border-4  border-dashed border-t-4 border-white rounded-full animate-spin"></div>
                  </div>
              :
              <div className="cursor-pointer flex items-center gap-2">
              <TfiComment  className="text-white"/>
              <p>Comment</p>
              </div>
              }
            </div>
            </div>

                    </div>

                </div>
              </div>
              {/*Add Comments */}


              {/* List of Comments whil will loop*/}
              <div className="flex flex-col px-3 sm:px-0 items-center">

              {post?.comments?.length > 0 ?
              post?.comments.map((comments, index) => (
                <Comment
                 Comment={comments}
                  postId = {post?._id}
                  refetch = {getPosts}
                  key={index} />
              ))
              :
              <p className="mt-10">No Comments Found</p>

              }
              </div>




              
              {/* List of Comments */}


              </div>
            </Col> 
         
            {/* Profile Column */}
            <Col xs={24} md={5} className={`${selected !== "profile" && isMobile ? "hidden" : "otherCol" } `} >
            <div className="w-full h-fit sm:p-10 lg:p-0 md:h-screen my-10">
              <img
              src={post?.user?.image? UPLOADS_URL+"/"+ post.user.image : "https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2247726673.jpg"}
              alt="Profile"
              className="w-60 h-60 sm:w-20 sm:h-20 lg:w-48 lg:h-48 rounded-full mx-auto"
              />

              <div className="flex flex-col gap-2">
              <p className="text-xl lg:text-xl whitespace-nowrap font-bold text-center mt-6">{post?.user && post.user.firstName + " " + post.user.lastName}</p>
              <p className="text-lg sm:text-xs whitespace-nowrap lg:text-sm font-normal text-center">Placement Management Expert</p>

            {/* Reviews */}
              <div>
                <div className="flex justify-center items-center gap-2 text-xl sm:text-lg lg:text-2xl">
                  <BsFillStarFill  />
                  <FaStarHalfAlt  />
                  <FaRegStar />
                  <FaRegStar  />
                  <FaRegStar  />
                </div>
              </div>

              <p className="text-2xl sm:text-lg lg:text-xl font-normal whitespace-nowrap text-center">0 Reviews</p>

            <div className="bg-blue-500 text-white w-44 sm:w-36 lg:w-44 text-lg sm:text-sm lg:text-lg font-semibold h-12 sm:h-8 lg:h-10 rounded-lg flex justify-center items-center gap-4 mt-5 ">
            Hourly Rates : {post?.rates?.hourlyRate ? "$"+" "+post?.rates?.hourlyRate : " - "}
            </div>

              </div>
            </div>
     
           </Col>
          </Row>
  
          
    </Layout>
    )
  }
  