import React, { useEffect, useRef, useState } from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Card,
  Tabs,
  Table,
  Image,
  Divider,
  Rate,
  Modal,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, UPLOADS_URL } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { PiMedal } from "react-icons/pi";
import {
  FaArrowRight,
  FaCheck,
  FaCheckCircle,
  FaMedal,
  FaRegClock,
  FaRegStar,
} from "react-icons/fa";
import { TbBolt } from "react-icons/tb";
import { LiaBrainSolid } from "react-icons/lia";
import swal from "sweetalert";
import { ImageUrl } from "../../helpers/Function";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosMail } from "react-icons/io";
const { Meta } = Card;
// import router from "next/router";

function Homepage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);
      const [modal2Open, setModal2Open] = useState(false);
      const videoRef = useRef(null);
    
      useEffect(() => {
        if (modal2Open && videoRef.current) {
          videoRef.current.play().catch((error) => {
            console.log("Autoplay failed:", error);
          });
        }
      }, [modal2Open]);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (!user && !token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const data1 = [
    {
      title: "Log in with company credentials using Single Sign-on.",
    },
    {
      title:
        "Notify external systems when employees complete training courses.",
    },
    {
      title: "Share badges & certificates with LinkedIn and Accredible.",
    },
  ];

  //   const data2 = [
  //     {
  //       title: "Interactive courses made of video, pdf, SCORM, HTML, and more.",
  //     },
  //     {
  //       title:
  //         "Single Sign-On for customers to log in using company credentials.",
  //     },
  //   ];

  const data3 = [
    {
      avatar: <GiHamburgerMenu />,
      title: "Track new hires progress",
      des: "Accurately track the progress of new hires in an onboarding program.",
    },
    {
      avatar: <FaRegClock />,
      title: "Automate HR",
      des: "Automate HR paperwork and reduce new-hire expenses.",
    },
    {
      avatar: <FaMedal />,
      title: "Guide employees",
      des: "Guide employees into professional development after their onboarding.",
    },
  ];
  // const data4 = [
  //   {
  //     avatar: <FaCheckCircle />,
  //     title: "Sell Course Licenses In Bulk",
  //     des: "and charge organizations with a one-time payment or a subscription model.",
  //   },
  //   {
  //     avatar: <FaCheckCircle />,
  //     title: "Offer Discounts Using Coupon Codes",
  //     des: "Number of active Users, Enrollment date, Last-active date.",
  //   },
  // ];

  const data5 = [
    {
      title: "Test knowledge retention with interactive assignments.",
    },
    {
      title: "Employees can train when they’re available & ready to learn.",
    },
  ];

  // const data6 = [
  //   {
  //     avatar: <FaCheckCircle />,
  //     title: "Customize Text Fields, Fonts & Sizes, Colors, and more...",
  //   },
  //   {
  //     avatar: <FaCheckCircle />,
  //     title: "Add Expiration Dates",
  //   },
  //   {
  //     avatar: <FaCheckCircle />,
  //     title: "Change Canvas Size & Design",
  //   },
  //   {
  //     avatar: <FaCheckCircle />,
  //     title: "Add An Organization ID",
  //   },
  // ];

  return (
    <Layout style={{ minHeight: "100vh", backgroundSize: "cover" }}>
      <section>
        <Row className="cource-banner" justify={"center"} align={"middle"}>
          <Col xs={24} md={10}>
            <div className="">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={20} className="formWrap">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg3.png")}
                  />
                  <p className="text-p-14">
                    LMS For Employee Development & Onboarding
                  </p>
                  <Typography.Title
                    className="fontFamily1 smallTitle"
                    style={{ color: "#fff" }}
                  >
                    {<> Maintain & Grow Teams With Employee Training</>}
                  </Typography.Title>

                  <Typography.Text
                    className="fontFamily1 smallText"
                    style={{ color: "#fff" }}
                  >
                    Help employees learn new skills, earn certifications, and
                    maintain professional knowledge with an all-inclusive
                    learning platform. Create courses and online workshops, then
                    track employee performance with one system.
                  </Typography.Text>
                  <br />
                  <br />
                  <br />
                  {!token && <Button
                    type="primary"
                    htmlType="submit"
                    className="whiteButton"
                    onClick={() => navigate("/signup")}
                    style={{ marginLeft: "5px" }}
                  >
                    Register Now
                  </Button>}
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                    onClick={() => setModal2Open(true)}
                    style={{ display: "inline-flex", alignItems: "center", gap: "5px" }}
                  >
                    See The Platform <FaArrowRight />
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={0} sm={0} md={12}>
            <div className="" style={{ textAlign: "center" }}>
              <Row style={{ width: "100%", paddingRight: "20px" }} gutter={40}>
                <Col xs={0} sm={12} md={24}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-78.png")}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-58.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg2.png")}
                  />
                  <h4>
                    Employee Training That Can Be Taken Any Place— Any Time
                  </h4>
                  <p className="text-p-16">
                    Create training courses that employees can take from
                    anywhere in the world, at any time. Courses can be made from
                    videos, docs, pdfs, text modules, SCORM, and more.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data5}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheck />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/pricingPlans")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-blue top-bottom-padding">
        <Row
          justify={"center"}
          style={{ textAlign: "center", paddingBottom: "40px" }}
        >
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 ">
              Reporting On Training Has Never Been Easier
            </h4>
            <p className="text-p-16 ">
              Automatically track the progress of employees in a training
              program. From completions and certifications to quiz scores and
              attendance records — keep track of everything happening in
              employee training.
            </p>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col xl={20} lg={18} md={23} xs={24}>
            <Row
              justify={"center"}
              gutter={[16, 16]}
              className="gutter-0"
              style={{ alignItems: "baseline" }}
            >
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-79.png")}
                  />
                  <h5 className="">
                    Manage Certifications and Training Attendance
                  </h5>
                  <p className="">
                    Manage employee training with a platform that keeps track of
                    who is certified and up to date on training.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-80.png")}
                  />
                  <h5 className="">
                    Monitor Assignment or Quiz Completions and Scores
                  </h5>
                  <p className="">
                    Monitor how employees are performing in online training
                    using software with built-in learner reports.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-81.png")}
                  />
                  <h5 className="">Track How Long Employees Are Training</h5>
                  <p className="">
                    Track how much time employees spend in training programs
                    using software that tracks user-activities.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Eliminate Inefficiencies In Professional Development</h4>
                  <p className="text-p-16">
                    Connect with 3rd-party tools to create high-quality
                    employees training that’s convenient for your company to
                    manage.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data1}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/pricingPlans")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-55.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 text-white">
              Fast-Track New Hire Onboarding
            </h4>
            <p className="text-p-16 text-white">
              Create an onboarding program that makes hiring new talent
              efficient and scalable.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => {
                navigate(token ? "/profile" : "/signup");
              }}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>

          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-82.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={10}>
                <div className="list-box">
                  <List
                    itemLayout="horizontal"
                    dataSource={data3}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <span className="list-icon">{item.avatar}</span>
                          }
                          title={<h6 className="list-h6">{item.title}</h6>}
                          description={<p className="list-des">{item.des}</p>}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-blue top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-83.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Separate Training By Department</h4>
                  <p className="text-p-16">
                    Use portals to train all of your teams online without having
                    one department’s training overlap with the next.
                  </p>
                  <div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="whiteButton"
                      style={{ marginLeft: "5px" }}
                    >
                      Management Team
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="whiteButton"
                      style={{ marginLeft: "5px" }}
                    >
                      Social Services
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="whiteButton"
                      style={{ marginLeft: "5px" }}
                    >
                      Education
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="whiteButton"
                      style={{ marginLeft: "5px" }}
                    >
                      Facilities
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="whiteButton"
                      style={{ marginLeft: "5px" }}
                    >
                      Nutrition
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="whiteButton"
                      style={{ marginLeft: "5px" }}
                    >
                      Parent Training
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="whiteButton"
                      style={{ marginLeft: "5px" }}
                    >
                      Board Development
                    </Button>
                  </div>
                  <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => {
                navigate(token ? "/profile" : "/signup");
              }}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>

                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding">
        <Row
          justify={"center"}
          style={{ textAlign: "center", paddingBottom: "40px" }}
        >
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 ">
              Test Your Employee's Understanding & Skills
            </h4>
            <p className="text-p-16 ">
              Attach quizzes, written assignments, and surveys in a course to
              gauge how well employees understand training material.
            </p>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col xl={20} lg={18} md={23} xs={24}>
            <Row
              justify={"center"}
              gutter={[16, 16]}
              className="gutter-0"
              style={{ alignItems: "baseline" }}
            >
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-84.png")}
                  />
                  <h5 className="">Create Quizzes & Tests</h5>
                  <p className="">
                    Create multiple-choice, multiple-correct, and other style
                    quizzes.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-85.png")}
                  />
                  <h5 className="">Give Employees Assignments</h5>
                  <p className="">
                    Test employees’ critical thinking with assignments.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-86.png")}
                  />
                  <h5 className="">Get Company Feedback</h5>
                  <p className="">
                    Get feedback from employees using different surveys types.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={ImageUrl("miniImg2.png")}
                />
                <h4 className="text-38 text-white">Improve Company Culture</h4>
                <p className="text-p-16 text-white">
                  Use WrightCo Academy to improve your organization’s culture
                  during training. Create team assignments that foster
                  collaboration, and train groups of employees face-to-face and
                  online, using virtual instructor-led training.
                </p>
                <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => {
                navigate(token ? "/profile" : "/signup");
              }}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>

              </Col>
              <Col xs={24} md={10}></Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38">Your Organization's Success Is Priority</h4>
            <div className="">
              {/* <Image
                preview={false}
                alt={"Failed to load image"}
                src={ImageUrl("new-88.png")}
                style={{ maxWidth: "150px" }}
              /> */}
            </div>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "20px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"top"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={3}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-87.png")}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={11}>
                <p className="review-p">
                  “We love the customization that WrightCo offers. We maintain
                  very high brand standards and through their brilliant team, we
                  were able to make our vision come to life. With WrightCo
                  fantastic team we have built a sophisticated platform.
                  WrightCo has proven to be professional, accommodating and
                  advanced from the beginning.”
                </p>
                <h6>Rebecca P.</h6>
                <span className="tab-green-text" style={{ fontSize: "16px" }}>
                  Ultimate Reality Inc.
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      {/* <section className="bg-white top-bottom-padding">
        <Row justify={"center"}>
          <Col xl={18} lg={17} md={23} xs={23}>
            <div className="on-demand">
              <h4>Efficiently Sell Courses & Webinars Online</h4>
              <Button
                type="primary"
                htmlType="submit"
                className="whiteButton"
                onClick={() => navigate("/about-us")}
                style={{ marginLeft: "5px" }}
              >
                Get Started
              </Button>
            </div>
          </Col>
        </Row>
      </section> */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Modal
          // title="Vertically centered modal dialog"
          centered
          open={modal2Open}
          onOk={() => setModal2Open(false)}
          onCancel={() => setModal2Open(false)}
          width={1000}
          footer={false}
        >
          <div
            style={{
              padding: "50px 20px",
            }}
          >
            <video width="auto" ref={videoRef} height="auto" controls>
              <source src={`https://react.customdev.solutions:3012/Uploads/studentVideo.mp4`} />
              <source src="movie.ogg" type="video/ogg" />
            </video>
          </div>
        </Modal>
      </Row>
    </Layout>
  );
}

export default Homepage;
