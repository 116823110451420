import React, { useEffect, useRef, useState } from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Card,
  Tabs,
  Table,
  Image,
  Divider,
  Rate,
  Modal,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, UPLOADS_URL } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { PiMedal } from "react-icons/pi";
import {
  FaArrowRight,
  FaCheck,
  FaCheckCircle,
  FaMedal,
  FaRegClock,
  FaRegStar,
} from "react-icons/fa";
import { TbBolt } from "react-icons/tb";
import { LiaBrainSolid } from "react-icons/lia";
import swal from "sweetalert";
import { ImageUrl } from "../../helpers/Function";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosMail } from "react-icons/io";
const { Meta } = Card;
// import router from "next/router";

function Homepage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const videoRef = useRef(null);
  
    useEffect(() => {
      if (modal2Open && videoRef.current) {
        videoRef.current.play().catch((error) => {
          console.log("Autoplay failed:", error);
        });
      }
    }, [modal2Open]);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (!user && !token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const data1 = [
    {
      title: "HubSpot",
    },
    {
      title: "Salesforce.",
    },
    {
      title: "Shopify.",
    },
    {
      title: "Google Analytics, FB pixel, and more.",
    },
  ];

  const data2 = [
    {
      title: "Provide course access to users directly after purchase.",
    },
  ];

  const data3 = [
    {
      avatar: <GiHamburgerMenu />,
      title: "Change Admin & Student Dashboards",
    },
    {
      avatar: <FaRegClock />,
      title: "Customize Your eCommerce Site",
    },
    {
      avatar: <FaMedal />,
      title: "Adjust Brand Texts such as Footers, Emails, & More",
    },
    {
      avatar: <FaRegStar />,
      title: "Create Landing Pages & Control Site Navigation",
    },
  ];

  const data4 = [
    {
      title: "Sell classes directly on the LMS or use an existing store.",
    },
    {
      title: "Provide course access to users directly after purchase.",
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh", backgroundSize: "cover" }}>
      <section>
        <Row className="cource-banner" justify={"center"} align={"middle"}>
          <Col xs={24} md={10}>
            <div className="">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={20} className="formWrap">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg3.png")}
                  />
                  <p className="text-p-14">integrations & api</p>
                  <Typography.Title
                    className="fontFamily1 smallTitle"
                    style={{ color: "#fff" }}
                  >
                    {<> Connect WrighCo Academy With Existing Tools</>}
                  </Typography.Title>

                  <Typography.Text
                    className="fontFamily1 smallText"
                    style={{ color: "#fff" }}
                  >
                    Earn revenue from your courses and programs, by selling them
                    online with our customizable landing pages, or by connecting
                    them to your own eCommerce site.
                  </Typography.Text>
                  <br />
                  <br />
                  <br />
                  {!token && <Button
                    type="primary"
                    htmlType="submit"
                    className="whiteButton"
                    onClick={() => navigate("/signup")}
                    style={{ marginLeft: "5px" }}
                  >
                    Register Now
                  </Button>}
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                    onClick={() => setModal2Open(true)}
                    style={{ display: "inline-flex", alignItems: "center", gap: "5px" }}
                  >
                    See The Platform <FaArrowRight />
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={0} sm={0} md={12}>
            <div className="" style={{ textAlign: "center" }}>
              <Row style={{ width: "100%", paddingRight: "20px" }} gutter={40}>
                <Col xs={0} sm={12} md={24}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-45.png")}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-46.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Bring Marketing & Sales to your eLearning Platform</h4>
                  <p className="text-p-16">
                    Connect WrightCo Acadmey to your CRM and other marketing
                    tools, to manage eLearning and business goals together.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data1}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/pricingPlans")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 text-white">
              Customize the LMS with REST API & JavaScript
            </h4>
            <p className="text-p-16 text-white">
              The sky is the limit with our flexible REST API that allows you to
              build out anything you like with the data from WrighCo Academy. Use
              Custom JavaScript to customize WrighCo Academy as well.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => {
                navigate(token ? "/profile" : "/signup");
              }}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={22}>
                <div className="" style={{ textAlign: "center" }}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-47.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg.png")}
                  />
                  <h4>Integrate with your eCommerce Store</h4>
                  <p className="text-p-16">
                    Advertise and sell tickets to upcoming in-person classes,
                    online courses, and webinars on your website.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data4}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/pricingPlans")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-16.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-gray top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-48.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Connect with Webinar Tools</h4>
                  <p className="text-p-16">
                    Connect with third-party apps to create the ultimate
                    eLearning platform. With our integrations and developer-
                    friendly API, your learners can continue their education and
                    train online using familiar business tools.
                  </p>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    Learn About Webinar Tools
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 text-white">
              Log in with Single Sign-on and 2-Step Verification
            </h4>
            <p className="text-p-16 text-white">
              Eliminate password fatigue by letting employees log in with their
              company credentials (Single-Sign on). Mitigate security risks with
              2-step verification.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => {
                navigate(token ? "/profile" : "/signup");
              }}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={22}>
                <div className="" style={{ textAlign: "center" }}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-49.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-gray top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Notify external systems automatically</h4>
                  <p className="text-p-16">
                    Using Webhooks, automatically send notifications to outside
                    systems when actions occur in the LMS.
                  </p>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/pricingPlans")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-50.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding">
        <Row justify={"center"}>
          <Col xl={18} lg={17} md={23} xs={23}>
            <div className="on-demand">
              <h4>Efficiently Sell Courses & Webinars Online</h4>
              <Button
                type="primary"
                htmlType="submit"
                className="whiteButton"
                onClick={() => {
                  navigate(token ? "/profile" : "/signup");
                }}
                style={{ marginLeft: "5px" }}
              >
                Get Started
              </Button>
            </div>
          </Col>
        </Row>
      </section>


      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Modal
          // title="Vertically centered modal dialog"
          centered
          open={modal2Open}
          onOk={() => setModal2Open(false)}
          onCancel={() => setModal2Open(false)}
          width={1000}
          footer={false}
        >
          <div
            style={{
              padding: "50px 20px",
            }}
          >
            <video width="auto" height="auto" ref={videoRef} controls>
              <source src={`https://react.customdev.solutions:3012/Uploads/studentVideo.mp4`} />
              <source src="movie.ogg" type="video/ogg" />
            </video>
          </div>
        </Modal>
      </Row>
    </Layout>
  );
}

export default Homepage;
