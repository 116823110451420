import React, { useEffect, useRef, useState } from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Card,
  Tabs,
  Table,
  Image,
  Divider,
  Rate,
  Modal,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, UPLOADS_URL } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { PiMedal } from "react-icons/pi";
import {
  FaArrowRight,
  FaCheck,
  FaCheckCircle,
  FaMedal,
  FaRegClock,
  FaRegStar,
} from "react-icons/fa";
import { TbBolt } from "react-icons/tb";
import { LiaBrainSolid } from "react-icons/lia";
import swal from "sweetalert";
import { ImageUrl } from "../../helpers/Function";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosMail } from "react-icons/io";
const { Meta } = Card;
// import router from "next/router";

function Homepage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);
      const [modal2Open, setModal2Open] = useState(false);
      const videoRef = useRef(null);
    
      useEffect(() => {
        if (modal2Open && videoRef.current) {
          videoRef.current.play().catch((error) => {
            console.log("Autoplay failed:", error);
          });
        }
      }, [modal2Open]);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (!user && !token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const data1 = [
    {
      title: "Train customers at their own pace.",
    },
    {
      title: "Help customers succeed and earn their trust.",
    },
    {
      title: "Increase customer retention.",
    },
  ];

  const data2 = [
    {
      title: "Interactive courses made of video, pdf, SCORM, HTML, and more.",
    },
    {
      title: "Single Sign-On for customers to log in using company credentials.",
    },
  ];

  const data3 = [
    {
      avatar: <FaCheckCircle />,
      title: "Review Customer Performances",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Review Customer Feedback And Identify Skills Gaps",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Encourage Improvement With Engaging Activities",
    },
  ];

  const data4 = [
    {
      avatar: <FaCheckCircle />,
      title: "Sell Course Licenses In Bulk",
      des: "and charge organizations with a one-time payment or a subscription model.",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Offer Discounts Using Coupon Codes",
      des: "Number of active Users, Enrollment date, Last-active date.",
    },
  ];

  const data5 = [
    {
      title: "Webinar tools such as Zoom, MS Teams, and more.",
    },
    {
      title: "CRMs like HubSpot and Salesforce.",
    },
    {
      title: "Analytics tools like GA4, FB Pixel, and JavaScript events.",
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh", backgroundSize: "cover" }}>
      <section>
        <Row className="cource-banner" justify={"center"} align={"middle"}>
          <Col xs={24} md={10}>
            <div className="">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={20} className="formWrap">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg3.png")}
                  />
                  <p className="text-p-14">LMS FOR CUSTOMER TRAINING</p>
                  <Typography.Title
                    className="fontFamily1 smallTitle"
                    style={{ color: "#fff" }}
                  >
                    {<> Boost Customer Satisfaction & Loyalty</>}
                  </Typography.Title>

                  <Typography.Text
                    className="fontFamily1 smallText"
                    style={{ color: "#fff" }}
                  >
                    Train your customers to become experts with your products,
                    using customer training software. See how WrightCo Acadmey
                    helps you create and manage training that is effective for
                    customers and efficient for your organization.
                  </Typography.Text>
                  <br />
                  <br />
                  <br />
                  {!token && <Button
                                      type="primary"
                                      htmlType="submit"
                                      className="whiteButton"
                                      onClick={() => navigate("/signup")}
                                      style={{ marginLeft: "5px" }}
                                    >
                                      Register Now
                                    </Button>}
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      className="loginButton"
                                      onClick={() => setModal2Open(true)}
                                      style={{display:"inline-flex" , alignItems:"center" , gap:"5px"}}
                                    >
                                      See The Platform <FaArrowRight />
                                    </Button>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={0} sm={0} md={12}>
            <div className="" style={{ textAlign: "center" }}>
              <Row style={{ width: "100%", paddingRight: "20px" }} gutter={40}>
                <Col xs={0} sm={12} md={24}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-56.png")}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>

      <section className="bg-blue top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-57.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Maximize Customer Retention</h4>
                  <p className="text-p-16">
                    Increase customer satisfaction by showing customers how to
                    use your product and succeed. Customer training created &
                    managed with WrightCo Academy helps you stand out from the
                    competition.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data1}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/pricingPlans")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg2.png")}
                  />
                  <h4>Train Customers On Their Time</h4>
                  <p className="text-p-16">
                    Increase the value customers get from your products, using
                    online courses that customers can go through in their own
                    time from anywhere in the world.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data2}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/pricingPlans")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-58.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 text-white">
            Strengthen Customer Relationships Online
            </h4>
            <p className="text-p-16 text-white">
            Since WrighCo.Academy keeps track of all user activity on the platform, you can quickly address skills gaps that customers can improve upon during training.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => {
                navigate(token ? "/profile" : "/signup");
              }}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-59.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={10}>
                <div className="list-box">
                  <List
                    itemLayout="horizontal"
                    dataSource={data3}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <span className="list-icon">{item.avatar}</span>
                          }
                          title={<h6 className="list-h6">{item.title}</h6>}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-gray top-bottom-padding">
        <Row
          justify={"center"}
          style={{ textAlign: "center", paddingBottom: "40px" }}
        >
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg4.png")}
            />
            <h4 className="text-38">
            Standardize Training
            For Global Companies
            </h4>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col xl={20} lg={18} md={23} xs={24}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[16, 16]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-34.png")}
                  />
                  <h5>Webinars & Seminars</h5>
                  <p>
                    Create multiple-choice, short answer, and other style
                    quizzes that can be static or dynamic.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-35.png")}
                  />
                  <h5>On-Demand Courses</h5>
                  <p>
                    Have learners read slides, answer questions, and participate
                    in different interactive assignments.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-36.png")}
                  />
                  <h5>Translate WrighCo Academy</h5>
                  <p>
                    Start a discussion for learners in a classroom to answer
                    questions individually and work together.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 text-white">
              Sell Training Directly On The LMS
            </h4>
            <p className="text-p-16 text-white">
              Fully customize the menus, buttons, and text sections in the LMS
              to better represent your company and training type.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => {
                navigate(token ? "/profile" : "/signup");
              }}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-28.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={10}>
                <div className="list-box">
                  <List
                    itemLayout="horizontal"
                    dataSource={data4}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <span className="list-icon">{item.avatar}</span>
                          }
                          title={<h6 className="list-h6">{item.title}</h6>}
                          description={<p className="list-des">{item.des}</p>}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      {/* <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg.png")}
                  />
                  <h4>All-In-One Platform For B2B Training</h4>
                  <p className="text-p-16">
                    WrightCo Academy connects with other software, so
                    organizations can use familiar business tools in their
                    training programs. See some examples of software you can use
                    with WrightCo Academy LMS:
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data5}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheck />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-55.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section> */}

      {/* <section className="bg-white top-bottom-padding">
        <Row justify={"center"}>
          <Col xl={18} lg={17} md={23} xs={23}>
            <div className="on-demand">
              <h4>Efficiently Sell Courses & Webinars Online</h4>
              <Button
                type="primary"
                htmlType="submit"
                className="whiteButton"
                onClick={() => navigate("/about-us")}
                style={{ marginLeft: "5px" }}
              >
                Get Started
              </Button>
            </div>
          </Col>
        </Row>
      </section> */}

<Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Modal
          // title="Vertically centered modal dialog"
          centered
          open={modal2Open}
          onOk={() => setModal2Open(false)}
          onCancel={() => setModal2Open(false)}
          width={1000}
          footer={false}
        >
          <div
            style={{
              padding: "50px 20px",
            }}
          >
            <video width="auto" ref={videoRef} height="auto" controls>
              <source src={`https://react.customdev.solutions:3012/Uploads/studentVideo.mp4`} />
              <source src="movie.ogg" type="video/ogg" />
            </video>
          </div>
        </Modal>
      </Row>
    </Layout>
  );
}

export default Homepage;
