import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Button,
  Row,
  Typography,
  Layout,
  Image,
  Card,
  Spin,
  Input,
  message,
  List,
} from "antd";
import { useNavigate, useParams } from "react-router";
import { UPLOADS_URL, COURSE, COMMENTS } from "../../config/constants/api";
import { Get } from "../../config/api/get";
import swal from "sweetalert";
import "react-alice-carousel/lib/alice-carousel.css";
import Comments from "../../components/Comments/index";
//icons
import { BsDot } from "react-icons/bs";
import { ImageUrl } from "../../helpers/Function";
import VideoPlayer from "../../components/VideoPlayer";
//icons

function CourseDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const [reviews, setReviews] = useState([]);
  const [ratings, setRatings] = useState({});
  const [course, setCourse] = useState({});
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const { Search } = Input;
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [range, setRange] = useState([10, 200]);
  const [review, setReview] = useState({
    averageRating: 0,
    totalReviews: 0,
  });
  const [rates, setRates] = useState({
    hourlyRate: 0,
    tutoringRate: 0,
    courseingRate: 0,
  });

  const item = { rating: 4 };

  useEffect(() => {
    getCourseDetails();
    if (token) {
      getMyCourses();
    }
  }, []);

  const getMyCourses = async (pageNumber, keyword, category) => {
    setLoading(true);
    try {
      const response = await Get(COURSE.getMyCourses, token, {
        limit: "100",
      });
      setLoading(false);
      if (response?.status) {
        setCourses(response?.data?.courses.map((item) => item._id));
      } else {
        message.error("Something went wrong!");
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const getCourseDetails = async () => {
    try {
      const response = await Get(COURSE.getCourseById + id);
      if (response?.status) {
        setCourse(response?.data);
      } else {
        swal("Error", response?.message, "error");
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{
            fontSize: "30px",
            color: "#333d47",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {<> Course Details</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{
          backgroundColor: "white",
          justifyContent: "center",
          padding: "50px",
        }}
        gutter={20}
      >
        <Col xs={24} md={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "10px",
            }}
          >
            <Card className="contactCard2" bordered={false}>
              {loading && (
                <Row style={{ minHeight: "30vh" }} className="flex">
                  <Spin size="large" />
                </Row>
              )}
              {!loading && (
                <div
                  style={{
                    width: "100%",
                    padding: "20px",
                    // display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <div>

                  <Image
                    src={
                      course?.course?.image
                        ? UPLOADS_URL + "/" + course?.course?.image
                        : ImageUrl("avatar.png")
                    }
                    height={350}
                    width={"100%"}
                    preview={false}
                    style={{ borderRadius: "10px", objectFit: "cover" }}
                  />
                  </div>
                  <div>
                {course?.course?.fileUrl && (
                    
                    <VideoPlayer data={course?.course} />
                  )}
                  </div>

                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "28px",
                      fontWeight: 800,
                      color: "black",
                      textAlign: "left",
                      marginTop: 30,
                    }}
                  >
                    {course?.course?.title}
                  </Typography.Title>
                  <Typography.Text
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      color: "grey",
                      textAlign: "center",
                      justifyContent: "center",
                      textAlign: "justify",
                    }}
                  >
                    {course?.course?.description}
                  </Typography.Text>
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "28px",
                      fontWeight: 800,
                      color: "black",
                      textAlign: "left",
                      marginTop: 20,
                    }}
                  >
                    Features
                  </Typography.Title>

                  {course?.course?.features
                    .toString()
                    .split(",")
                    .map((item) => {
                      return (
                        <Typography.Text
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            color: "grey",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            // justifyContent: "center",
                            textAlign: "justify",
                          }}
                        >
                          <BsDot style={{ fontSize: "30px", margin: 0 }} />
                          {item}
                        </Typography.Text>
                      );
                    })}
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "28px",
                      fontWeight: 800,
                      color: "black",
                      textAlign: "left",
                      marginTop: 30,
                    }}
                  >
                    Course Outline
                  </Typography.Title>
                  <Typography.Text
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      color: "grey",
                      textAlign: "center",
                      justifyContent: "center",
                      textAlign: "justify",
                    }}
                  >
                    {course?.course?.courseOutline}
                  </Typography.Text>
                  <br />
                  <br />
                  <Row gutter={20}>
                    <Col xs={12} sm={12}>
                      {course?.course?.files ? (
                        <div>
                          <h1 className="mb-2">Documents</h1>
                          <RecordViewer pdfLinks={course?.course?.files} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>

                  <br />
                  <br />

                  {token && !courses.includes(course?.course?._id) && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="loginButton"
                      onClick={() =>
                        navigate("/payment/" + course?.course?._id, {
                          state: { type: "COURSE" },
                        })
                      }
                    >
                      Register Now
                    </Button>
                  )}
                </div>
              )}
            </Card>
            <Comments courseId={id} />
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default CourseDetails;

const RecordViewer = ({ pdfLinks }) => {
  return (
    <List
      bordered
      dataSource={pdfLinks}
      style={{ width: "max-content" }}
      renderItem={(item) => (
        <List.Item>
          <a
            href={UPLOADS_URL + item}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black" }}
          >
              {(item.split(".")[1] || "") + (item.split(".")[2] ? "." + item.split(".")[2] : item)}
          </a>
        </List.Item>
      )}
    />
  );
};
